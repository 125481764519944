require('./bootstrap');

// bootstrap 5
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

// flatpickr for datetime picker
import flatpickr from "flatpickr";
window.flatpickr = flatpickr;

import Dutch from "flatpickr/dist/l10n/nl.js";
window.flatpickrDutch = Dutch;

// import Lightbox from "lightbox2/dist/js/lightbox.min.js";
// window.lightbox = Lightbox;
require('lightbox2');

window.moment = require('moment-timezone');

$(document).ready(() => {
    $('[data-toggle="tooltip"]').tooltip();
});
